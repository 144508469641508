<template>
  <div class="articleDetail">
    <div class="border">
      <div class="title">{{ detail.title }}</div>
      <div class="foot">
        <span
          class="item-foot-1 iconfont icon-dibiao1"
          v-if="detail.area_name"
        ></span>
        <span class="ml-30">{{ detail.format_add_time }}</span>
        <span class="ml-60 iconfont icon-check-line"></span>
        <span style="font-size: 0.24rem; line-height: 0.34rem" class="ml-20">{{
          detail.click_count
        }}</span>
      </div>
      <div v-html="detail.content" class="content"></div>
      <div v-if="detail.tpe == 3">
        <div class="imgs" v-for="(item, index) in detail.images" :key="index">
          <img
            :src="$IMG_PATH + item"
            alt=""
            @click="showImg(detail.images, index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, ImagePreview } from "vant";
import { getDetail } from "../api";
import { record } from "../../../../src/api";
export default {
  name: "articleDetail",
  data() {
    return {
      detail: {},
      content: "",
    };
  },
  created() {
    let id = this.$route.params.id;
    this.init(id);
    this.recordNum(id);
  },
  methods: {
    init(id) {
      let params = {
        id: id,
      };
      getDetail(params).then((res) => {
        console.log(res);
        this.detail = res.data;
      });
    },
    recordNum(id) {
      let params = {
        device: "WX",
        tpe: 2,
        id: id,
      };
      record(params).then((res) => {});
    },
    showImg(imgList, index) {
      let imgs = [];
      imgList.forEach((item) => {
        imgs.push(this.$IMG_PATH + item);
      });
      this.instance_show = ImagePreview({
        images: imgs,
        startPosition: index,
      });
    },
  },
};
</script>

<style scoped lang="less">
.articleDetail {
  width: 100%;
  padding: 0.24rem 0.2rem;
  overflow: hidden;
  .border {
    border-radius: 0.1rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0.24rem 0.2rem;
    overflow: hidden;
    background-color: #fff;
    overflow: hidden;
    .title {
      font-size: 0.32rem;
      color: #333;
      letter-spacing: 0.02rem;
      font-weight: 600;
      margin-bottom: 0.15rem;
      line-height: 0.5rem;
    }
    .foot {
      color: #8b8b8b;
      font-size: 0.24rem;
      margin-top: 0.3rem;
      display: flex;
      align-items: center;
      .item-foot-1 {
        width: 0.17rem;
        height: 0.2rem;
      }
      .item-foot-2 {
        display: inline-block;
        min-width: 1.4rem;
      }
      .item-foot-4 {
        width: 0.27rem;
        height: 0.15rem;
      }
      .ml-20 {
        margin-left: 0.2rem;
      }
      .ml-30 {
        margin-left: 0.3rem;
      }
      .ml-60 {
        margin-left: 2.6rem;
      }
      .ml-10 {
        margin-left: 0.1rem;
      }
    }
    .content {
      color: #333;
      line-height: 0.5rem;
      margin-top: 0.45rem;
      text-align: justify;
      letter-spacing: 0.01rem;
      text-indent: 0.56rem;
      font-size: 0.28rem !important;
      /deep/ img {
        width: 100% !important;
        height: auto;
        margin: 0 auto 0.3rem;
        display: block;
      }
    }
    .imgs {
      width: 100%;
      height: 3.6rem;
      margin-top: 0.2rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>